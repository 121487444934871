import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store";
import CoreuiVue from "@coreui/vue";
import { iconsSet as icons } from "./assets/icons/icons.js";
import Vuelidate from "vuelidate";
import Notifications from "vue-notification";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import config from "@/config";
import * as VueGoogleMaps from "vue2-google-maps";
import 'jquery/src/jquery.js'
// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'jquery/src/jquery.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import VueAgile from 'vue-agile'
Vue.use(VueAgile)

// import * as vuePusher from 'vue-pusher';
import VuePusher from 'vue-pusher';

import VueSocialauth from "vue-social-auth";
import VueAxios from "vue-axios";
import axios from "axios";

import Firebase from "firebase";
import { firestorePlugin } from "vuefire";
Vue.use(firestorePlugin);

import VueCryptojs from "vue-cryptojs";
Vue.use(VueCryptojs);


var firebaseConfig = {
    apiKey: "AIzaSyDAsTPJuLMC7cZkbcnHnwgfK1HQgRe8LEU",
    authDomain: "agile-airship-267408.firebaseapp.com",
    projectId: "agile-airship-267408",
    storageBucket: "agile-airship-267408.appspot.com",
    messagingSenderId: "422701054371",
    appId: "1:422701054371:web:2a0222aa2b9ac8c9dcc216",
    measurementId: "G-XCRH49T2P3"
};


Firebase.initializeApp(firebaseConfig);

Vue.use(Notifications);
Vue.use(Vuelidate);
Vue.component("multiselect", Multiselect);

Vue.use(VueAxios, axios);

// Vue.use(VueSocialauth, {
//     providers: {
//         google: {
//             clientId:
//                 "893141114484-a5f2mj197c0r62mq1sdl9jo7ts9o2fjj.apps.googleusercontent.com",
//             redirectUri: "/auth/google/callback", // Your client app URL
//         },
//     },
// });

import "@/permission"; // permission control
import outsideDir from "@/outsideclick"; // outside click controller

Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.directive("click-outside", outsideDir);

Vue.filter("toCurrency", function (value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
    });
    return formatter.format(value);
});

// const VuePusher = require('vue-pusher');
Vue.use(VuePusher, {
    api_key: process.env.VUE_APP_PUSHER_API_KEY,
    options: {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
        encrypted: true,
    }
});

Vue.use(VueGoogleMaps, {
    load: {
        key: config.get(process.env.NODE_ENV).GOOGLE_API_KEY,
        libraries: "places", // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
    },

    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    // autobindAllEvents: false,

    //// If you want to manually install components, e.g.
    //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    //// Vue.component('GmapMarker', GmapMarker)
    //// then disable the following:
    // installComponents: true,
});

new Vue({
    el: "#app",
    router,
    store,
    icons,
    template: "<App/>",
    components: {
        App,
    },
});
//

