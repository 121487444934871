import Vue from "vue";
import Router from "vue-router";

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Views
const Dashboard = () => import("@/views/Dashboard");

// Views - Pages
const Page404 = () => import("@/views/pages/Page404");
const Page500 = () => import("@/views/pages/Page500");
// const Login = () => import("@/views/pages/Login");
const Register = () => import("@/views/pages/Register");
const ForgotPassword = () => import("@/views/client/ForgotPassword");
// Users
const Users = () => import("@/views/users/Users");
const ContractLengths = () => import("@/views/contractLengths/ContractLengths");
const Layout = () => import("@/views/layout/layout");

// Properties
const Properties = () => import("@/views/properties/Properties");
const Property = () => import("@/views/properties/Property");
const ClientDashBoard = () => import("@/views/client/Dashboard");
const ListProperties = () => import("@/views/client/ListProperties");
const DetailProperty = () => import("@/views/client/DetailProperty");
const ContractDetails = () => import("@/views/client/ContractDetails");
const UserContractDetails = () => import("@/views/client/UserContractDetails");
const ContectUs = () => import("@/views/contectUs/ContectUs");
const Offers = () => import("@/views/offers/Offers");

//amenities
const Amenities = () => import("@/views/amenities/Amenities");
const NearByAmenities = () => import("@/views/nearbyAmenities/NearbyAmenities");
const Contract = () => import("@/views/Contract/contracts");
const TheProfile = () => import("@/views/profile/TheProfile");
const ThePassword = () => import("@/views/password/ThePassword.vue")
//additional-options
const AdditionalOptions = () =>
    import("@/views/additional_options/AdditionalOption");

Vue.use(Router);

export default new Router({
    mode: "history",
    linkActiveClass: "active",
    scrollBehavior: () => ({ y: 0 }),
    routes: configRoutes(),
    base: process.env.NODE_ENV === "production" ? "/" : "/",
});

function configRoutes() {
    return [
        {
            path: "/admin",
            redirect: "/admin/users",
            name: "Home",
            component: TheContainer,
            meta: { requiresAuth: true, adminAuth: true },
            children: [
                {
                    path: "dashboard",
                    name: "Dashboard",
                    component: Dashboard,
                },
                {
                    path: "users",
                    meta: { label: "Users" },
                    component: {
                        render(c) {
                            return c("router-view");
                        },
                    },
                    children: [
                        {
                            path: "",
                            component: Users,
                        },
                    ],
                },
                {
                    path: "amenities",
                    meta: { label: "Amenities" },
                    component: {
                        render(c) {
                            return c("router-view");
                        },
                    },
                    children: [
                        {
                            path: "",
                            component: Amenities,
                        },
                    ],
                },
                {
                    path: "additionalOptions",
                    meta: { label: "Additional Options" },
                    component: {
                        render(c) {
                            return c("router-view");
                        },
                    },
                    children: [
                        {
                            path: "",
                            component: AdditionalOptions,
                        },
                    ],
                },
                {
                    path: "properties",
                    meta: { label: "Properties" },
                    component: {
                        render(c) {
                            return c("router-view");
                        },
                    },
                    children: [
                        {
                            path: "",
                            component: Properties,
                        },
                        {
                            path: ":id",
                            meta: { label: "Properties Details" },
                            name: "Property",
                            component: Property,
                        },
                    ],
                },
                {
                    path: "contract-lengths",
                    meta: { label: "Contract Lengths" },
                    component: {
                        render(c) {
                            return c("router-view");
                        },
                    },
                    children: [
                        {
                            path: "",
                            component: ContractLengths,
                        },
                        {
                            path: ":id",
                            meta: { label: "Contract Length Details" },
                            name: "Contract Lengths",
                            // component: ContractLength,
                        },
                    ],
                },
                {
                    path: "layout",
                    meta: { label: "Layout Designs" },
                    component: {
                        render(c) {
                            return c("router-view");
                        },
                    },
                    children: [
                        {
                            path: "",
                            component: Layout,
                        },
                        // {
                        //     path: ":id",
                        //     meta: { label: "Contract Length Details" },
                        //     name: "Contract Lengths",
                        //     // component: ContractLength,
                        // },
                    ],
                },
                {
                    path: "nearby-amenities",
                    meta: { label: "NearByAmenities" },
                    component: {
                        render(c) {
                            return c("router-view");
                        },
                    },
                    children: [
                        {
                            path: "",
                            component: NearByAmenities,
                        },
                    ],
                },
                {
                    path: "contract",
                    meta: { label: "Contracts" },
                    component: {
                        render(c) {
                            return c("router-view");
                        },
                    },
                    children: [
                        {
                            path: "",
                            component: Contract,
                        },
                    ],
                },


                {
                    path: "profile",
                    meta: { label: "TheProfile" },
                    component: {
                        render(c) {
                            return c("router-view");
                        },
                    },
                    children: [
                        {
                            path: "",
                            component: TheProfile,
                        },
                    ],
                },

                {
                    path: "changepassword",
                    meta: { label: "ThePassword" },
                    component: {
                        render(c) {
                            return c("router-view");
                        },
                    },
                    children: [
                        {
                            path: "",
                            component: ThePassword,
                        },
                    ],
                },


                {
                    path: "contect-us",
                    meta: { label: "Contact Us" },
                    component: {
                        render(c) {
                            return c("router-view");
                        },
                    },
                    children: [
                        {
                            path: "",
                            component: ContectUs,
                        },
                    ],
                },
                {
                    path: "offers",
                    meta: { label: "Offers" },
                    component: {
                        render(c) {
                            return c("router-view");
                        },
                    },
                    children: [
                        {
                            path: "",
                            component: Offers,
                        },
                    ],
                },
            ],
        },
        {
            path: "/pages",
            redirect: "/pages/404",
            name: "Pages",
            component: {
                render(c) {
                    return c("router-view");
                },
            },
            children: [
                {
                    path: "404",
                    name: "Page404",
                    component: Page404,
                },
                {
                    path: "500",
                    name: "Page500",
                    component: Page500,
                },
                // {
                //   path: 'login',
                //   name: 'Login',
                //   component: Login
                // },
                {
                    path: "register",
                    name: "Register",
                    component: Register,
                },
            ],
        },
        {
            path: "/",
            name: "dashBoard",
            component: ClientDashBoard,
            children: [
                {
                    path: "/list",
                    name: "List",
                    component: ListProperties,
                    meta: { requiresAuth: false },
                },
                {
                    path: "/detail/:id",
                    name: "Detail",
                    component: DetailProperty,
                    meta: { requiresAuth: true },
                },
                {
                    path: "/contract/:contractId",
                    name: "Contract Details",
                    component: ContractDetails,
                    meta: { requiresAuth: true },
                },
                {
                    path: "/contracts",
                    name: "User Contract Details",
                    component: UserContractDetails,
                    meta: { requiresAuth: true },
                },
                {
                    path: "/profile",
                    name: "User Profile Details",
                    component: TheProfile,
                    meta: { requiresAuth: true },
                },
                {
                    path: "/changepassword",
                    name: "User Password change Details",
                    component: ThePassword,
                    meta: { requiresAuth: true },
                },
                {
                    path: "/auth/reset-password/:hash",
                    name: "Reset Password",
                    component: ForgotPassword,
                    meta: { requiresAuth: false },
                },
            ],
        },
        {
            path: "*",
            component: Page404,
        },
    ];
}
